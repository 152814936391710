<template>
    <div>
        <div class="bg-white p-5 col-12 col-md-6 col-lg-4 offset-0 offset-md-3 offset-lg-4">
            <button class="btn btn-primary" @click="ro = !ro">
                {{ ro ? 'Apenas Leitura' : 'Editável' }}
            </button>

            <card-text-synonym
                v-model="data"
                :read-only="ro"
                :index="1"
                @onDelete="remore(index)"
                allow-attachments
                :max-attachments="3"
            />

            <label>Título</label>
            <textarea class="form-control" v-model="data.title" cols="30" rows="10"></textarea>
            <label>Texto</label>
            <textarea class="form-control" v-model="data.text" cols="30" rows="10"></textarea>
        </div>
    </div>
</template>

<script>
import CardTextSynonym from '@/views/components/CardTextSynonym/index.vue';

export default {
    components: {
        CardTextSynonym
    },
    data() {
        return {
            data: {
                title: "Apêndicite _aguda_",
                text: "Apêndicite _aguda_\n* Olá ~mâe~\n> Eae",
                attachments: [],
                synonyms: []
            },
            ro: false
        }
    },
    methods: {
        remore(e) {
            console.log(`Removido: ${e}`)
        },
    }
}
</script>